import React from 'react';
import './OldBillsPage.css';

const OldBillsPage = () => {
  // Exemplo de dados da tabela (simulados)
  const data = [
    { date: '8 de Outubro de 2021', volume: 5555.0, valor: 5555.55 }
  ];

  const lastUpdate = '8 de Outubro de 2021';

  return (
    <div className="old-bills-page">
      <h2>Contas antigas </h2>
      <p className="last-update">Última atualização: {lastUpdate}</p>
      <table className="old-bills-table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Volume (m³)</th>
            <th>Valor R$</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.date}</td>
              <td>{row.volume.toFixed(1)}</td>
              <td>{row.valor.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OldBillsPage;
