import React from 'react';
import './Dropdown.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({ id, label, options, onChange, required }) => {
    return (
        <div className="dropdown">
            {/* Adicionando o label externo ao select */}
            <label htmlFor={id} className="dropdown-label">
                {label} {required && <span className="asterisk">*</span>}
            </label>
            
            <div className="select-wrapper">
                <select id={id} onChange={onChange} defaultValue="">
                    <option value="" disabled hidden>Selecione uma opção</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
            </div>
        </div>
    );
};

export default Dropdown;
