import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../components/Dropdown/Dropdown';
import './InsertReading.css';

const InsertReadingPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reading, setReading] = useState('');
  const [selectedPoint, setSelectedPoint] = useState('');

  // Simulação de dados de última leitura e consumo médio
  const lastReading = 1200; // valor da última leitura
  const lastReadingDate = new Date(2023, 8, 15); // Data da última leitura
  const averageConsumption = 30; // Consumo médio

  // Simulação de pontos de medição
  const points = [
    { value: 'point1', label: 'Ponto de Medição 1' },
    { value: 'point2', label: 'Ponto de Medição 2' },
    { value: 'point3', label: 'Ponto de Medição 3' },
  ];

  const handlePointChange = (e) => {
    setSelectedPoint(e.target.value);
  };

  const handleInsertReading = () => {
    if (!selectedDate || !reading || !selectedPoint) {
      alert('Preencha todos os campos.');
      return;
    }

    // Simular envio para a API
    console.log('Leitura inserida:', {
      date: selectedDate,
      reading,
      point: selectedPoint,
    });
    alert('Leitura inserida com sucesso!');
  };

  return (
    <div className="insert-reading-page">
      <h2>Inserção de Leitura de Hidrômetro</h2>
      <div className="reading-container">
        <div className="last-reading-info">
          <p className="blue-text"><strong>Última Leitura:</strong> {lastReading} m³</p>
          <p className="blue-text"><strong>Data da Última Leitura:</strong> {lastReadingDate.toLocaleDateString()}</p>
          <p className="blue-text"><strong>Consumo Médio:</strong> {averageConsumption} m³/dia</p>
        </div>

        <div className="form-group">
          <label>Selecione a Data e Hora da Leitura <span className="asterisk">*</span></label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            dateFormat="Pp" // Formato de data e hora
            className="custom-datepicker-input"
          />

          <label>Insira o Volume Lido (m³) <span className="asterisk">*</span></label>
          <input
            type="number"
            value={reading}
            onChange={(e) => setReading(e.target.value)}
            placeholder="Digite a leitura"
            className="custom-input"
          />

          <Dropdown
            label="Selecione o Ponto de Medição"
            options={points}
            value={selectedPoint}
            onChange={handlePointChange}
            required={true}
          />
        </div>

        <button className="insert-reading-button" onClick={handleInsertReading}>
          Inserir Leitura
        </button>
      </div>
    </div>
  );
};

export default InsertReadingPage;
