import React, { useEffect, useState } from 'react';
import apiClient from '../../api/apiClient';

const ClientDropdown = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await apiClient.get('/api/clients');
        setClients(response.data); // Ajuste conforme a estrutura de dados retornada
        console.log(response.data)
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    };

    fetchClients();
  }, []);

  return (
    <div>
      <label>Selecione o Cliente</label>
      <select
        value={selectedClient}
        onChange={(e) => setSelectedClient(e.target.value)}
      >
        <option value="">Escolha um cliente</option>
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ClientDropdown;
