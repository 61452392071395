import React, { useState } from 'react';
import './ChangePasswordPage.css';

const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Função para verificar se as senhas coincidem e fazer o request para a API
  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage('A nova senha e a confirmação não coincidem');
      return;
    }

    // Montar o payload
    const payload = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    // Fazer o request PUT para a API (exemplo de URL)
    const url = 'https://api.exemplo.com/user/change-password';
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Senha alterada com sucesso!');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage('');
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Erro ao alterar a senha');
      }
    } catch (error) {
      setErrorMessage('Erro ao se conectar à API');
    }
  };

  return (
    <div className="change-password-page">
      <h2>Alterar senha</h2>
      <div className="change-password-container">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label htmlFor="oldPassword">Senha antiga*</label>
            <input
              type="password"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">Nova senha*</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <ul className="password-hints">
              <li>Sua senha não pode ser muito parecida com o resto das suas informações pessoais.</li>
              <li>Sua senha precisa conter pelo menos 8 caracteres.</li>
              <li>Sua senha não pode ser uma senha comumente utilizada.</li>
              <li>Sua senha não pode ser inteiramente numérica.</li>
            </ul>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirmação da nova senha*</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button className="change-button" onClick={handleChangePassword}>
            Alterar
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
