import React, { useState } from 'react';
import "./EditEmailPage.css"

const EditEmailPage = () => {
  // Exemplo de dados da tabela (simulados)
  const [data, setData] = useState([
    {
      id: 1,
      nome: 'João Silva',
      email: 'joao.silva@exemplo.com',
      isEditing: false,
    },
    {
      id: 2,
      nome: 'Maria Oliveira',
      email: 'maria.oliveira@exemplo.com',
      isEditing: false,
    },
  ]);

  // Função para alternar entre modo de edição e exibição
  const handleEdit = (index) => {
    const updatedData = data.map((row, i) =>
      i === index ? { ...row, isEditing: !row.isEditing } : row
    );
    setData(updatedData);
  };

  // Função para salvar as edições e fazer o request para a API
  const handleSave = async (index, editedRow) => {
    // Atualiza o estado local primeiro
    const updatedData = data.map((row, i) =>
      i === index ? { ...editedRow, isEditing: false } : row
    );
    setData(updatedData);

    // Faz o request PUT para a API
    const url = `https://api.exemplo.com/users/${editedRow.id}`; // Use a URL da sua API
    const payload = {
      email: editedRow.email,
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Email atualizado com sucesso');
      } else {
        console.error('Falha ao atualizar o email');
      }
    } catch (error) {
      console.error('Erro ao fazer o request:', error);
    }
  };

  // Função para atualizar os campos durante a edição
  const handleChange = (index, field, value) => {
    const updatedData = data.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setData(updatedData);
  };

  return (
    <div className="edit-email-page">
      <h2>Editar Email</h2>
      <table className="edit-email-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.nome}</td>
              <td>
                {row.isEditing ? (
                  <input
                    type="email"
                    value={row.email}
                    onChange={(e) => handleChange(index, 'email', e.target.value)}
                  />
                ) : (
                  row.email
                )}
              </td>
              <td>
                {row.isEditing ? (
                  <button
                    className="save-button"
                    onClick={() => handleSave(index, row)}
                  >
                    Salvar
                  </button>
                ) : (
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(index)}
                  >
                    Editar
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EditEmailPage;
