import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import "./RegisterPage.css"

const RegisterPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('As senhas não coincidem.');
            return;
        }
        try {
            const response = await axios.post('http://api.akvofluo.com/api/register/', {
                username: name,
                email: email,
                password: password,
                first_name: name.split(' ')[0] || ''
            });
            console.log(Response)
            const userId = response.data.id;
            navigate(`/register-manager/${userId}`);
            setName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setSuccessMessage('Cadastro realizado com sucesso!');
        } catch (error) {
            setErrorMessage('Erro ao realizar o cadastro.');
        }
    };

    return (
        <div className="page-container">
            <div className="form-box">
                <h2>Cadastre-se</h2>
            
                <form onSubmit={handleRegister}>
                    <label>Username</label>
                        <input
                            type="text"
                            placeholder="Digite seu nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    <label>
                        Email
                        <input
                            type="email"
                            placeholder="Digite seu email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Senha
                        <input
                            type="password"
                            placeholder="Digite sua senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Confirmar senha
                        <input
                            type="password"
                            placeholder="Confirme sua senha"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </label>
                    <small>
                        Ao clicar no botão Avançar, você concorda com nossos <a href="/terms">Termos de Uso</a> e nossa <a href="/privacy">Política de Privacidade</a>.
                    </small>
                    <button type="submit">Avançar</button>
                </form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>
    );
};

export default RegisterPage;
