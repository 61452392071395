import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../components/Dropdown/Dropdown';
import LabelWithTooltipAndAsterisk from '../../components/Tooltip/Tooltip'; // Wrapper para Tooltip e Asterisco
import tooltipImage from '../../assets/example-image.jpg'; // Exemplo de imagem
import './InsertNewBillPage.css';

const InsertNewBillPage = () => {
  const [selectedPoint, setSelectedPoint] = useState('');
  const [readingDate, setReadingDate] = useState(new Date());
  const [consumedVolume, setConsumedVolume] = useState('');
  const [billAmount, setBillAmount] = useState('');
  const [competenceDate, setCompetenceDate] = useState(new Date());

  // Simulação de pontos de medição
  const points = [
    { value: 'point1', label: 'Ponto de Medição 1' },
    { value: 'point2', label: 'Ponto de Medição 2' },
    { value: 'point3', label: 'Ponto de Medição 3' },
  ];

  const handlePointChange = (e) => {
    setSelectedPoint(e.target.value);
  };

  const handleInsertBill = () => {
    if (!selectedPoint || !readingDate || !consumedVolume || !billAmount || !competenceDate) {
      alert('Preencha todos os campos.');
      return;
    }

    // Simular envio para a API
    console.log('Conta inserida:', {
      point: selectedPoint,
      readingDate,
      consumedVolume,
      billAmount,
      competenceDate,
    });
    alert('Conta nova inserida com sucesso!');
  };

  return (
    <div className="insert-bill-page">
      <h2>Inserção de Conta Nova</h2>
      <div className="bill-container">
        <div className="form-group">
          {/* Dropdown sem Tooltip */}
          <Dropdown
            label="Selecione o Ponto de Medição"
            options={points}
            value={selectedPoint}
            onChange={handlePointChange}
            required={true}
          />

          {/* Input Data de Leitura com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Data de Leitura"
            tooltipImage={tooltipImage}
            required={true}
          >
            <DatePicker
              selected={readingDate}
              onChange={(date) => setReadingDate(date)}
              dateFormat="dd/MM/yyyy"
              className="custom-datepicker-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Input Volume Consumido com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Volume Consumido (m³)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <input
              type="number"
              value={consumedVolume}
              onChange={(e) => setConsumedVolume(e.target.value)}
              placeholder="Digite o volume consumido"
              className="custom-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Input Valor da Conta com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Valor da Conta (R$)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <input
              type="number"
              value={billAmount}
              onChange={(e) => setBillAmount(e.target.value)}
              placeholder="Digite o valor da conta"
              className="custom-input"
            />
          </LabelWithTooltipAndAsterisk>

          {/* Competência com Tooltip */}
          <LabelWithTooltipAndAsterisk
            label="Competência (Mês/Ano)"
            tooltipImage={tooltipImage}
            required={true}
          >
            <DatePicker
              selected={competenceDate}
              onChange={(date) => setCompetenceDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="custom-datepicker-input"
            />
          </LabelWithTooltipAndAsterisk>
        </div>

        <button className="insert-bill-button" onClick={handleInsertBill}>
          Inserir Conta
        </button>
      </div>
    </div>
  );
};

export default InsertNewBillPage;