import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
    return (
        <div className="homepage">
            <h1>CONSUMA DE FORMA CONSCIENTE COM A AKVOFLUO</h1>
            <p>
                Segundo a ONU, em 2025 <strong>1.8 bilhão</strong> de pessoas viverá em países ou
                regiões com escassez absoluta de água e <strong>2/3 da população mundial</strong> poderá viver em condições de escassez de água.
            </p>
            <p>Conheça seu padrão de consumo e comece a consumir de forma consciente.</p>
            <div className="homepage-buttons">
                <Link to="/login" className="homepage-button">Login</Link>
                <Link to="/register" className="homepage-button">Cadastro</Link>
            </div>
            {/* Aqui você pode adicionar as imagens e outras informações */}
        </div>
    );
};

export default HomePage;
