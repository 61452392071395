import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PhotoUploadPage.css';

const PhotoUploadPage = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [photos, setPhotos] = useState([]);
  const [batchSize, setBatchSize] = useState(50);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // URLS
  const clientsUrl = 'http://api.akvofluo.com/api/clients';
  const uploadUrl = 'http://api.akvofluo.com/api/upload/list/';

  // Fetch clients for the dropdown
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(clientsUrl);
        setClients(response.data);
      } catch (err) {
        console.error('Erro ao buscar clientes:', err);
      }
    };
    fetchClients();
  }, []);

  // Handle client selection
  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setPhotos(files);
  };

  // Handle form submission
  const handleUpload = async () => {
    if (!selectedClient || photos.length === 0) {
      setError('Selecione um cliente e carregue ao menos uma foto.');
      return;
    }

    setError('');
    setSuccessMessage('');
    const batches = [];

    // Split photos into batches
    for (let i = 0; i < photos.length; i += batchSize) {
      batches.push(photos.slice(i, i + batchSize));
    }

    // Upload each batch
    for (const [index, batch] of batches.entries()) {
      const formData = new FormData();
      formData.append('client', selectedClient);
      batch.forEach((photo, idx) => {
        formData.append(`photo${idx}`, photo);
      });

      try {
        const response = await axios.post(uploadUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log(`Batch ${index + 1} enviado com sucesso:`, response.data);
      } catch (err) {
        console.error(`Erro ao enviar batch ${index + 1}:`, err);
        setError('Erro ao enviar algumas fotos. Verifique a conexão e tente novamente.');
        return;
      }
    }

    setSuccessMessage('Todas as fotos foram enviadas com sucesso!');
    setPhotos([]);
  };

  return (
    <div className="photo-upload-page">
      <div className="photo-container">
        <h2>Inserção de Fotos</h2>

        {/* Dropdown de clientes */}
        <div className="form-group">
          <label htmlFor="clientDropdown">Selecione o Cliente:</label>
          <select
            id="clientDropdown"
            className="dropdown"
            value={selectedClient}
            onChange={handleClientChange}
          >
            <option value="">Selecione...</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
        </div>

        {/* Input de arquivos */}
        <div className="form-group">
          <label htmlFor="photoUpload">Carregar Fotos:</label>
          <input
            type="file"
            id="photoUpload"
            multiple
            accept="image/*"
            className="custom-file-input"
            onChange={handleFileChange}
          />
        </div>

        {/* Botão de envio */}
        <button className="upload-button" onClick={handleUpload}>
          Enviar Fotos
        </button>

        {/* Mensagens de erro ou sucesso */}
        {error && <p className="error">{error}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
      </div>
    </div>
  );
};

export default PhotoUploadPage;
