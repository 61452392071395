import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom"; // Importa useParams
import "../RegisterPage/RegisterPage.css";

const RegisterManagerPage = () => {
    const [managerData, setManagerData] = useState({
        user: "", // Será preenchido com o userId da URL
        name: "",
    });

    const [contactData, setContactData] = useState({
        email: "",
        phone: "",
        is_primary: true,
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { userId } = useParams(); // Pega o userId da URL
    const navigate = useNavigate();

    // Atualiza os dados do formulário
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (Object.keys(managerData).includes(name)) {
            setManagerData({
                ...managerData,
                [name]: type === "checkbox" ? checked : value,
            });
        } else {
            setContactData({
                ...contactData,
                [name]: type === "checkbox" ? checked : value,
            });
        }
    };

    // Envia o formulário
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Envia os dados do gestor, incluindo o userId da URL

            const managerResponse = await axios.post(
                "http://localhost:8000/api/managers/",
                {
                    ...managerData,
                    user: userId, // Usa o userId da URL para associar o manager ao usuário
                }
            );
            console.log(managerResponse.data)

            const managerId = managerResponse.data.id;

            // Envia os dados do contato associado ao gestor
            const managerContactResponse = await axios.post("http://localhost:8000/api/manager-contacts/", {
                ...contactData,
                manager: managerId,
            });

            console.log(managerContactResponse.data)

            // Limpa os campos
            setManagerData({
                user: "",
                name: "",
            });

            setContactData({
                email: "",
                phone: "",
                is_primary: true,
            });
            
            // Redireciona para a página de cadastro do cliente
            navigate(`/register-client/${managerId}`);
            
            setSuccessMessage("Gestor e contato cadastrados com sucesso!");
            
        } catch (error) {
            if (error.response) {
                // Obtém os erros retornados pela API
                const apiErrors = error.response.data; // Objeto retornado, por exemplo: {status: 'Erro', erros: {...}}
        
                // Transforma os erros em uma string legível
                const formattedErrors = JSON.stringify(apiErrors, null, 2);
        
                console.error("Erro da API:", formattedErrors); // Mostra no console para depuração
                // Define a mensagem de erro como uma string
                setErrorMessage(`Erro: ${apiErrors.status}\nDetalhes: ${JSON.stringify(apiErrors.erros)}`);
            } else if (error.request) {
                // Caso nenhum retorno tenha sido recebido
                console.error("Erro de rede ou sem resposta:", error.request);
                setErrorMessage("Erro de rede: Nenhuma resposta do servidor.");
            } else {
                // Para erros inesperados
                console.error("Erro ao configurar a requisição:", error.message);
                setErrorMessage("Erro inesperado: " + error.message);
            }
        }
    };

    return (
        <div className="page-container">
            <div className="form-box">
                <h2>Cadastrar Gestor</h2>
                <form onSubmit={handleSubmit}>
                    {/* Dados do Gestor */}
                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nome do gestor"
                            value={managerData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {/* Dados de Contato */}
                    <h3>Contato Principal</h3>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={contactData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Telefone</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Telefone"
                            value={contactData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="is_primary">Contato Principal</label>
                        <input
                            type="checkbox"
                            id="is_primary"
                            name="is_primary"
                            checked={contactData.is_primary}
                            onChange={handleChange}
                        />
                    </div>

                    <button type="submit">Cadastrar</button>
                </form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>
    );
};

export default RegisterManagerPage;
