import React from 'react';
import NavBar from '../components/NavBar/NavBar'; // Navbar para rotas privadas
import FooterPainel from '../components/FooterPainel/FooterPainel'; // Footer para rotas privadas
import SideBar from '../components/SideBar/SideBar'; // Sidebar para rotas privadas
import { Outlet } from 'react-router-dom';
import './PrivateLayout.css';

const PainelLayout = () => {
    return (
        <div className="layout-container">
            <SideBar />
            <div className="main-area">
                <NavBar />
                <div className="page-content">
                    <Outlet />
                </div>
                <FooterPainel />
            </div>
        </div>
    );
};

export default PainelLayout;

