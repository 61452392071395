import React from 'react';
import './LinkGenerationPage.css';

const LinkGenerationPage = () => {
  return (
    <div className="link-generation-page">
      {/* Seção para Inserção Manual de Dados */}
      <div className="link-section">
        <h2>Link Externo - Inserção Manual de Dados</h2>
        <p>
          O link gerado permite o acesso à funcionalidade de inserção de dados no sistema, sem a
          necessidade de senhas, portanto seja cauteloso ao compartilhá-lo.
        </p>
        <p>Compartilhe com aqueles que farão as leituras diárias:</p>
        <ul>
          <li>Encarregados</li>
          <li>Porteiros</li>
          <li>Outros</li>
        </ul>

        <p className="confirm-info-title">Confirme suas informações:</p>
        <p><strong>Nome de usuário ou email:</strong> admin</p>
        <p><strong>Estado da Federação:</strong> DF</p>

        <button className="show-link-button">Mostrar link</button>
      </div>

      {/* Seção para Visualização das Informações */}
      <div className="link-section">
        <h2>Link Externo - Visualização das Informações</h2>
        <p>
          O link gerado permite o acesso ao painel de controle do edifício apenas para leitura.
        </p>

        <p className="confirm-info-title">Confirme suas informações:</p>
        <p><strong>Nome de usuário ou email:</strong> admin</p>
        <p><strong>Estado da Federação:</strong> DF</p>

        <button className="show-link-button">Mostrar link</button>
      </div>
    </div>
  );
};

export default LinkGenerationPage;
