import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './NewBillsPage.css';

const NewBillsPage = () => {
  const [data, setData] = useState([
    {
      id: 1, // ID do recurso, necessário para identificar na API
      dataLeitura: new Date('2021-10-08'),
      mesAno: new Date('2021-10'),
      volume: 5555.0,
      leitura: 55555.0,
      valor: 5555.55,
      isEditing: false,
    },
  ]);

  // Função para alternar entre modo de edição e exibição
  const handleEdit = (index) => {
    const updatedData = data.map((row, i) =>
      i === index ? { ...row, isEditing: !row.isEditing } : row
    );
    setData(updatedData);
  };

  // Função para salvar as edições e fazer o request para a API
  const handleSave = async (index, editedRow) => {
    // Atualiza o estado local primeiro
    const updatedData = data.map((row, i) =>
      i === index ? { ...editedRow, isEditing: false } : row
    );
    setData(updatedData);

    // Faz o request PUT para a API
    const url = `https://api.exemplo.com/bills/${editedRow.id}`; // Use a URL da sua API
    const payload = {
      dataLeitura: editedRow.dataLeitura.toISOString(),
      mesAno: editedRow.mesAno.toISOString(),
      volume: editedRow.volume,
      leitura: editedRow.leitura,
      valor: editedRow.valor,
    };

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Dados atualizados com sucesso');
      } else {
        console.error('Falha ao atualizar os dados');
      }
    } catch (error) {
      console.error('Erro ao fazer o request:', error);
    }
  };

  // Função para atualizar os campos durante a edição
  const handleChange = (index, field, value) => {
    const updatedData = data.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setData(updatedData);
  };

  return (
    <div className="new-bills-page">
      <h2>Contas Novas</h2>
      <table className="new-bills-table">
        <thead>
          <tr>
            <th>Data de leitura</th>
            <th>Mês / Ano</th>
            <th>Volume (m³)</th>
            <th>Leitura (m³)</th>
            <th>Valor R$</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>
                {row.isEditing ? (
                  <DatePicker
                    selected={row.dataLeitura}
                    onChange={(date) => handleChange(index, 'dataLeitura', date)}
                    dateFormat="dd/MM/yyyy"
                  />
                ) : (
                  row.dataLeitura.toLocaleDateString('pt-BR')
                )}
              </td>
              <td>
                {row.isEditing ? (
                  <DatePicker
                    selected={row.mesAno}
                    onChange={(date) => handleChange(index, 'mesAno', date)}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                  />
                ) : (
                  `${row.mesAno.getMonth() + 1}/${row.mesAno.getFullYear()}`
                )}
              </td>
              <td>
                {row.isEditing ? (
                  <input
                    type="number"
                    value={row.volume}
                    onChange={(e) => handleChange(index, 'volume', e.target.value)}
                  />
                ) : (
                  row.volume.toFixed(1)
                )}
              </td>
              <td>
                {row.isEditing ? (
                  <input
                    type="number"
                    value={row.leitura}
                    onChange={(e) => handleChange(index, 'leitura', e.target.value)}
                  />
                ) : (
                  row.leitura.toFixed(1)
                )}
              </td>
              <td>
                {row.isEditing ? (
                  <input
                    type="number"
                    value={row.valor}
                    onChange={(e) => handleChange(index, 'valor', e.target.value)}
                  />
                ) : (
                  row.valor.toFixed(2)
                )}
              </td>
              <td>
                {row.isEditing ? (
                  <button
                    className="save-button"
                    onClick={() => handleSave(index, row)}
                  >
                    Salvar
                  </button>
                ) : (
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(index)}
                  >
                    Editar
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewBillsPage;
