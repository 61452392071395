import React from 'react';
import './PaymentInstructionsPage.css';

const PaymentInstructionsPage = () => {
  return (
    <div className="payment-instructions-page">
      <h2>Instruções de Pagamento</h2>
      <p>Aceitamos pagamentos por Transferência Bancária ou PIX</p>
      
      <div className="payment-details">
        <h3>Dados para Transferência:</h3>
        <p><strong>Banco Inter [077]</strong></p>
        <p><strong>Conta Corrente:</strong> 137061064</p>
        <p><strong>Agência:</strong> 0001</p>
        <p><strong>CNPJ:</strong> 42.001.025/0001-50</p>
        <p><strong>Favorecido:</strong> AKVOFLUO ENGENHARIA E TECNOLOGIA LT</p>
        
        <h3>PIX</h3>
        <p><strong>Chave PIX (CNPJ):</strong> 42.001.025/0001-50</p>
        <p><strong>Favorecido:</strong> AKVOFLUO ENGENHARIA E TECNOLOGIA LT</p>
        
        <h3>Valores:</h3>
        <p><strong>Mensal:</strong> R$149,90</p>
        <p><strong>Anual:</strong> R$ ----,--</p>
      </div>
      
      <p className="instructions">
        Enviar comprovante para liberação do sistema informando nome do "Cliente" cadastrado:
      </p>
      
      <div className="contact-details">
        <p><strong><i className="fas fa-envelope"></i> Email:</strong> contato@akvofluo.com</p>
        <p><strong><i className="fas fa-phone"></i> Telefone:</strong> +55 61 98564 0765</p>
      </div>
    </div>
  );
};

export default PaymentInstructionsPage;
