import React from 'react';
import './WaterMeterReadings.css';

const WaterMeterReadings = () => {
  return (
    <div className="water-meter-readings">
      <h1>App de Leituras Individualizadas</h1>
      <p>
        Utilize o sidebar à esquerda para gerar o relatório de individualização alternativa, 
        seguindo o fluxo de inserção:
      </p>
      <ol>
        <li>Conta Mensal</li>
        <li>Fotos</li>
        <li>Leituras</li>
      </ol>
    </div>
  );
};

export default WaterMeterReadings;
