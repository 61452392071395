import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropdown from '../../components/Dropdown/Dropdown';
import FileUpload from '../../components/FileUpload/FileUpload';
import './UploadImgPage.css';

const UploadImgPage = () => {
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date()); // Inicializa com a data atual
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleClientChange = (e) => setSelectedClient(e.target.value);

  const handleUpload = () => {
    if (!selectedClient || !selectedDate || selectedFiles.length === 0) {
      alert('Preencha todos os campos e selecione as imagens.');
      return;
    }

    // Extrair mês e ano da data selecionada
    const selectedMonth = selectedDate.getMonth() + 1; // getMonth() retorna 0-11, por isso adicionamos 1
    const selectedYear = selectedDate.getFullYear();

    // Simular envio para a API
    const formData = new FormData();
    formData.append('client', selectedClient);
    formData.append('month', selectedMonth);
    formData.append('year', selectedYear);
    selectedFiles.forEach((file) => {
      formData.append('photos', file);
    });

    // Aqui você faria a requisição para a API
    console.log('Enviar fotos', formData);
    alert('Fotos enviadas com sucesso!');
  };

  return (
    <div className="upload-page">
      <h2>Upload de Fotos para auditoria</h2>
      <div className="form-group">
        <Dropdown
          label="Selecione o Cliente"
          options={[
            { value: 'client1', label: 'Cliente 1' },
            { value: 'client2', label: 'Cliente 2' },
          ]}
          value={selectedClient}
          onChange={handleClientChange}
          required={false}
        />
        <label>Selecione o Mês e Ano</label>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className="custom-datepicker-input"
        />
      </div>
      <div className="file-upload-container">
        <FileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
      </div>
      <button className="upload-button" onClick={handleUpload}>
        Enviar Fotos
      </button>
    </div>
  );
};

export default UploadImgPage;