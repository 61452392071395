import React from 'react';
import './FooterPainel.css'; // Certifique-se de criar este arquivo CSS

const FooterPainel = () => {


return (
    <footer className="footer">
        <div className="footer-content">
        Copyright © Akvofluo 2023
        </div>
    </footer>
);
};

export default FooterPainel;
