import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ConsumptionTablePage.css';

const ConsumptionTablePage = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  // Exemplo de dados da tabela (simulados)
  const data = [
    { date: '08/10/2021', leitura: 555.0, consumoDiario: 0.0, consumoAcumulado: 0.0 },
    { date: '10/11/2021', leitura: 1555.0, consumoDiario: 1000.0, consumoAcumulado: 1000.0 },
    { date: '10/12/2021', leitura: 2555.0, consumoDiario: 1000.0, consumoAcumulado: 2000.0 },
    { date: '10/01/2022', leitura: 3555.0, consumoDiario: 1000.0, consumoAcumulado: 3000.0 },
    { date: '10/02/2022', leitura: 4555.0, consumoDiario: 1000.0, consumoAcumulado: 4000.0 },
    { date: '15/02/2022', leitura: 4705.0, consumoDiario: 150.0, consumoAcumulado: 4150.0 },
  ];

  const handleSearch = () => {
    if (!startDate || !endDate) {
      alert('Por favor, selecione uma data inicial e uma data final.');
      return;
    }

    const filtered = data.filter((row) => {
      const rowDate = new Date(row.date.split('/').reverse().join('-')); // Converte a string de data para um objeto Date
      return rowDate >= startDate && rowDate <= endDate;
    });

    setFilteredData(filtered);
  };

  return (
    <div className="consumption-table-page">
      <h2>Tabelas</h2>
      <div className="search-section">
        <p>Consulte seu consumo pelas datas</p>
        <div className="datepickers-container">
          <div className="datepicker-group">
            <label>Data inicial</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/dd/yyyy"
              className="custom-datepicker"
            />
          </div>
          <div className="datepicker-group">
            <label>Data final</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="MM/dd/yyyy"
              className="custom-datepicker"
            />
          </div>
          <button className="search-button" onClick={handleSearch}>
            Pesquisar
          </button>
        </div>
      </div>

      <table className="consumption-table">
        <thead>
          <tr>
            <th>Data</th>
            <th>Leitura (m³)</th>
            <th>Consumo diário (m³)</th>
            <th>Consumo acumulado</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((row, index) => (
              <tr key={index}>
                <td>{row.date}</td>
                <td>{row.leitura.toFixed(1)}</td>
                <td>{row.consumoDiario.toFixed(1)}</td>
                <td>{row.consumoAcumulado.toFixed(1)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">Nenhum dado encontrado para o período selecionado.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ConsumptionTablePage;
