import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Para navegação
import './SideBar.css';
import logoAkvofluo from '../../assets/images/logo_Akvofluo_para_Fundo_Escuro.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTachometerAlt, 
    faQuestionCircle, 
    faTable, 
    faCog, 
    faCreditCard, 
    faMapMarkerAlt, 
    faUser, 
    faCircleChevronLeft, 
    faWater, 
    faChevronRight, 
    faChevronDown 
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    // Estados para expandir os menus
    const [isExpandedData, setIsExpandedData] = useState(false);
    const [isExpandedTables, setIsExpandedTables] = useState(false);
    const [isExpandedConfig, setIsExpandedConfig] = useState(false);
    const [isExpandedPayment, setIsExpandedPayment] = useState(false);
    
    const navigate = useNavigate();  // Hook para navegação

    // Funções para alternar a expansão dos itens
    const toggleExpandData = () => setIsExpandedData(!isExpandedData);
    const toggleExpandTables = () => setIsExpandedTables(!isExpandedTables);
    const toggleExpandConfig = () => setIsExpandedConfig(!isExpandedConfig);
    const toggleExpandPayment = () => setIsExpandedPayment(!isExpandedPayment);

    // Função para redirecionamento
    const handleNavigation = (path) => {
        navigate(path); // Redireciona para a rota desejada
    };

    return (
        <div className="sidebar">
            <a className="sidebar-logo-link" href="https://akvofluo.com.br/">
                <img src={logoAkvofluo} alt="Logo Akvofluo" className="sidebar-logo" />
            </a>
            <div className="sidebar-divider"></div>

            {/* Itens do menu */}
            <div className='sidebar-items'>
                <div className="sidebar-item" onClick={() => handleNavigation('/dashboard')}>
                    <FontAwesomeIcon icon={faTachometerAlt} className="sidebar-item-icon" />
                    Dashboard
                </div>
                <div className="sidebar-divider"></div>

                <div className="sidebar-item" onClick={() => handleNavigation('/instrucoes')}>
                    <FontAwesomeIcon icon={faQuestionCircle} className="sidebar-item-icon" />
                    Instruções
                </div>
                <div className="sidebar-divider"></div>

                {/* "Inserir dados" com a funcionalidade de expandir */}
                <div className="sidebar-item" onClick={toggleExpandData}>
                    <FontAwesomeIcon icon={faWater} className="sidebar-item-icon" />
                    Inserir dados
                    <FontAwesomeIcon 
                        icon={isExpandedData ? faChevronDown : faChevronRight} 
                        className="sidebar-expand-icon"
                    />
                </div>

                {/* Sub-itens de "Inserir dados" */}
                {isExpandedData && (
                    <div className="sidebar-subitems">
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/inserir-leitura')}>
                            Inserir Nova Leitura
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/inserir-conta-nova')}>
                            Inserir Conta Nova
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/inserir-conta-antiga')}>
                            Inserir Conta Antiga
                        </div>
                    </div>
                )}

                <div className="sidebar-divider"></div>

                {/* "Tabelas" com a funcionalidade de expandir */}
                <div className="sidebar-item" onClick={toggleExpandTables}>
                    <FontAwesomeIcon icon={faTable} className="sidebar-item-icon" />
                    Tabelas
                    <FontAwesomeIcon 
                        icon={isExpandedTables ? faChevronDown : faChevronRight} 
                        className="sidebar-expand-icon"
                    />
                </div>

                {/* Sub-itens de "Tabelas" */}
                {isExpandedTables && (
                    <div className="sidebar-subitems">
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/leitura-tabela')}>
                            Leituras
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/historico-consumo')}>
                            Histórico de Leituras
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/contas-antigas')}>
                            Contas Antigas
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/contas-novas')}>
                            Contas Novas
                        </div>
                    </div>
                )}

                <div className="sidebar-divider"></div>

                {/* "Configurações" com a funcionalidade de expandir */}
                <div className="sidebar-item" onClick={toggleExpandConfig}>
                    <FontAwesomeIcon icon={faCog} className="sidebar-item-icon" />
                    Configurações
                    <FontAwesomeIcon 
                        icon={isExpandedConfig ? faChevronDown : faChevronRight} 
                        className="sidebar-expand-icon"
                    />
                </div>

                {/* Sub-itens de "Configurações" */}
                {isExpandedConfig && (
                    <div className="sidebar-subitems">
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/alterar-email')}>
                            Alterar Email
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/alterar-senha')}>
                            Alterar Senha
                        </div>
                    </div>
                )}

                <div className="sidebar-divider"></div>

                {/* "Pagamento" com a funcionalidade de expandir */}
                <div className="sidebar-item" onClick={toggleExpandPayment}>
                    <FontAwesomeIcon icon={faCreditCard} className="sidebar-item-icon" />
                    Pagamento
                    <FontAwesomeIcon 
                        icon={isExpandedPayment ? faChevronDown : faChevronRight} 
                        className="sidebar-expand-icon"
                    />
                </div>

                {/* Sub-itens de "Pagamento" */}
                {isExpandedPayment && (
                    <div className="sidebar-subitems">
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/instrucoes-pagamento')}>
                            Instruções
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/historico-pagamento')}>
                            Status/Histórico
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/pagamento-extrato')}>
                            Carrinho
                        </div>
                        <div className="sidebar-subitem" onClick={() => handleNavigation('/pagamento-historico')}>
                            Renovar
                        </div>
                    </div>
                )}

                <div className="sidebar-divider"></div>

                <div className="sidebar-item" onClick={() => handleNavigation('/endereco')}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="sidebar-item-icon" />
                    Endereço
                </div>
                <div className="sidebar-divider"></div>
                <div className="sidebar-item" onClick={() => handleNavigation('/perfil')}>
                    <FontAwesomeIcon icon={faUser} className="sidebar-item-icon" />
                    Perfil
                </div>
            </div>

            <div className="sidebar-divider"></div>

            {/* Botão de voltar */}
            <div className="sidebar-back-button">
                <FontAwesomeIcon icon={faCircleChevronLeft} className="icon" />
            </div>
        </div>
    );
};

export default Sidebar;
