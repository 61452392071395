import React, { useState } from 'react';
import './AddressPage.css';

const AddressPage = () => {
  // Estados para os campos de endereço
  const [address, setAddress] = useState({
    cep: '',
    street: '',
    neighborhood: '',
    city: 'Brasília',
    state: 'Distrito Federal',
  });

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Função para manipular as mudanças nos campos de endereço
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  // Função para enviar os dados para a API ao salvar
  const handleSave = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await fetch('https://api.exemplo.com/address', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(address),
      });

      if (!response.ok) {
        throw new Error('Erro ao salvar o endereço');
      }

      setSuccess('Endereço salvo com sucesso!');
    } catch (err) {
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="address-page">
      <h2>Adicionar endereço</h2>
      <div className="address-form">
        <label>
          CEP:
          <input
            type="text"
            name="cep"
            value={address.cep}
            onChange={handleChange}
            placeholder="Digite o CEP"
          />
        </label>
        <label>
          Endereço:
          <input
            type="text"
            name="street"
            value={address.street}
            onChange={handleChange}
            placeholder="Digite o endereço"
          />
        </label>
        <label>
          Bairro:
          <input
            type="text"
            name="neighborhood"
            value={address.neighborhood}
            onChange={handleChange}
            placeholder="Digite o bairro"
          />
        </label>
        <label>
          Cidade:
          <input
            type="text"
            name="city"
            value={address.city}
            onChange={handleChange}
            placeholder="Digite a cidade"
          />
        </label>
        <label>
          Estado:
          <select name="state" value={address.state} onChange={handleChange}>
            <option value="Distrito Federal">Distrito Federal</option>
            {/* Adicione outros estados conforme necessário */}
          </select>
        </label>
        <button onClick={handleSave} disabled={saving}>
          {saving ? 'Salvando...' : 'Salvar'}
        </button>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </div>
    </div>
  );
};

export default AddressPage;
