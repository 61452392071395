import React, { useState, useEffect } from 'react';
import './PaymentHistoryPage.css';

const PaymentHistoryPage = () => {
  // Estados para armazenar as informações da API
  const [status, setStatus] = useState('');
  const [clientInfo, setClientInfo] = useState({});
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para gerenciar o carregamento
  const [error, setError] = useState(null); // Estado para gerenciar erros

  // Função para buscar os dados da API
  const fetchPaymentStatus = async () => {
    try {
      const response = await fetch('https://api.exemplo.com/payment-status'); // Use a URL correta da API
      if (!response.ok) {
        throw new Error('Nenhum dado de pagamento encontrado'); // Lançar erro com mensagem personalizada
      }
      const data = await response.json();

      // Atualizar os estados com os dados recebidos
      setStatus(data.status);
      setClientInfo({
        name: data.client.name,
        email: data.client.email,
        phone: data.client.phone,
        paidDate: data.paidDate,
        compensatedPeriod: data.compensatedPeriod,
      });
      setOrderHistory(data.orders);
    } catch (err) {
      setError('Nenhum dado de pagamento encontrado'); // Definir mensagem de erro personalizada
    } finally {
      setLoading(false); // Sempre parar o carregamento
    }
  };

  // useEffect para disparar a requisição quando o componente for montado
  useEffect(() => {
    fetchPaymentStatus();
  }, []); // O array vazio significa que isso será executado apenas uma vez quando o componente for montado

  if (loading) {
    return <p>Carregando dados...</p>; // Mostrar um indicador de carregamento
  }

  if (error) {
    return (
      <div className="error-message">
        <h2>Ops!</h2>
        <p>{error}</p>
        <p>Não encontramos nenhum registro de pagamento. Por favor, verifique novamente mais tarde.</p>
      </div>
    );
  }

  return (
    <div className="payment-history-page">
      <div className="status-box">
        <h3>Situação: <span className="status">{status}</span></h3>
        <p><strong>Cliente:</strong> {clientInfo.name}</p>
        <p><strong>Email:</strong> {clientInfo.email}</p>
        <p><strong>Telefone:</strong> {clientInfo.phone}</p>
        <p><strong>Pago em:</strong> {clientInfo.paidDate}</p>
        <p><strong>Período Compensado:</strong> {clientInfo.compensatedPeriod}</p>
      </div>

      <div className="history-box">
        <h3>Histórico de pedidos</h3>
        <table className="order-history-table">
          <thead>
            <tr>
              <th>Produto</th>
              <th>Data do pedido</th>
              <th>Cupom</th>
              <th>Preço</th>
              <th>Situação</th>
            </tr>
          </thead>
          <tbody>
            {orderHistory.length > 0 ? (
              orderHistory.map((order, index) => (
                <tr key={index}>
                  <td>{order.product}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.coupon}</td>
                  <td>{order.price}</td>
                  <td>{order.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Nenhum pedido encontrado</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentHistoryPage;
