import React, { useState, useEffect } from 'react';

// Get da API
import getDashboardData from '../../api/dashboardData';
import { startDataUpdate } from '../../api/dashboardData';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaucet, faTint, faMoneyBillWave, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import Card from '../../components/Card/Card';
import Dropdown from '../../components/Dropdown/Dropdown';
import ProgressCard from '../../components/ProgressCard/ProgressCard';
import PizzaChart from '../../components/PizzaChart/PizzaChart';
import Chart from '../../components/Chart/Chart';
import BotaoRelatorio from '../../components/BotaoRelatorio/BotaoRelatorio';
import WaterConsumptionCard from '../../components/WaterConsumptionCard/WaterConsumptionCard';

import './Dashboard.css';

const DashBoard = () => {
    

    const [selectedView, setSelectedView] = useState('Diário'); // Estado para visualizar diário ou mensal
    const [selectedPeriod, setSelectedPeriod] = useState('2023-11'); // Estado para o mês ou ano
    const [dashboardData, setDashboardData] = useState(null);

    // Dados de mockup para o gráfico principal
    const dailyDataByMonth = {
        '2023-11': Array.from({ length: 30 }, () => Math.floor(Math.random() * 30) + 10), // Dados de novembro
        '2023-12': Array.from({ length: 31 }, () => Math.floor(Math.random() * 30) + 10), // Dados de dezembro
        '2024-01': Array.from({ length: 31 }, () => Math.floor(Math.random() * 30) + 10)  // Dados de janeiro
    };
    const monthlyData = {
        '2023': [500, 620, 480], // Dados anuais de 2023
        '2024': [530, 610, 490]  // Dados anuais de 2024
    };

    const handleViewChange = (event) => {
        setSelectedView(event.target.value);
        setSelectedPeriod(selectedView === 'Diário' ? '2023-11' : '2023'); // Reseta o segundo dropdown ao alterar a visualização
    };
    

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
    };

    // Botão Gerar Relatório -----------------------------------------------------------
    /*const handleRelatorioClick = () => {
        console.log('Gerando relatório...');
        const input = document.getElementById('mainContent');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save(`Relatório_${selectedLabel}.pdf`);
        });
    };*/
    // ---------------------------------------------------------------------------------

    const viewOptions = [
        { label: 'Diário', value: 'Diário' },
        { label: 'Mensal', value: 'Mensal' }
    ];

    const periodOptions = selectedView === 'Diário'
        ? [
            { label: 'Novembro 2023', value: '2023-11' },
            { label: 'Dezembro 2023', value: '2023-12' },
            { label: 'Janeiro 2024', value: '2024-01' }
        ]
        : [
            { label: '2023', value: '2023' },
            { label: '2024', value: '2024' }
        ];

    // Determina dados e rótulos para o gráfico com base na seleção
    const chartData = selectedView === 'Diário' ? dailyDataByMonth[selectedPeriod] : monthlyData[selectedPeriod.slice(0, 4)];
    const chartLabels = selectedView === 'Diário' 
        ? Array.from({ length: chartData.length }, (_, i) => `${i + 1} ${selectedPeriod.slice(5)}`) 
        : ['Jan', 'Fev', 'Mar'];


    // Dados dos Cards -----------------------------------------------------------------
    const cardsData = dashboardData ? [
        {
            title: 'CONSUMO DE ÁGUA DO MÊS ATUAL',
            value: `${dashboardData.consumo_atual} Litros`,
            icon: <FontAwesomeIcon icon={faFaucet} />,
            className: 'blue-border'
        },
        {
            title: 'CONSUMO DE ÁGUA PROJETADO',
            value: `${dashboardData.consumo_projetado.toFixed(2)} Litros`,
            icon: <FontAwesomeIcon icon={faTint} />,
            className: 'blue-border'
        },
        {
            title: 'VALOR PROJETADO DA CONTA',
            value: `R$ ${dashboardData.valor_projetado.toFixed(2)}`,
            icon: <FontAwesomeIcon icon={faMoneyBillWave} />,
            className: 'green-border'
        },
        {
            title: 'ESTIMATIVA DE ECONOMIA',
            value: `R$ ${dashboardData.estimativa_economia.toFixed(2)}`,
            icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
            className: 'green-border'
        }
    ] : [];
    // ---------------------------------------------------------------------------------    
    // ---------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    // Renderização da página ----------------------------------------------------------
    return (
        <div id="mainContent" className="main-content">
            <div className='dashboard-header'>
                <div className='dashboard-header-text'>Dashboard - SQS 211 Bloco G</div>
            </div>
            
            <div className='dropdown-container'>
                <div className='dropdown1'>
                    <Dropdown
                        id="viewDropdown"
                        label="Visualizar"
                        options={viewOptions}
                        onChange={handleViewChange}
                    />
                </div>
                <div className='dropdown2'>
                    <Dropdown
                        id="periodDropdown"
                        label="Período"
                        options={periodOptions}
                        onChange={handlePeriodChange}
                    />
                </div>
            </div>

            <div className='periodo-consolidado'>Período Consolidado de 09/11/2023 a 08/12/2023</div>
            
            <div className='cards'> 
                {cardsData.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        value={card.value}
                        icon={card.icon}
                        className={card.className}
                    />
                ))}
            </div>

            <div className='card-barra-progresso'>
                <ProgressCard
                    title="Consumo percentual relativo à faixa atual (3ª faixa de tarifação)"
                    subtitle="Faixa de R$ 8.14 / R$ 8.14 (água / esgoto)"
                    progress={57}
                />
            </div>
            
            <div className='chart-container'>
                <div className='chart-barra'>
                    <Chart
                        title={`Consumo em ${selectedPeriod}`}
                        labels={chartLabels}
                        dailyData={chartData}
                    />
                </div>

                <div className='chart-pizza'>
                    <PizzaChart
                        title="Consumo por dia da semana (m³)"
                        data={[12, 14, 13, 17, 14, 16, 13]} // Dados de exemplo para a pizza
                        labels={['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']}
                    />
                </div>
            </div>
            
            {dashboardData ? (
                <div>
                    <pre>{JSON.stringify(dashboardData, null, 2)}</pre>
                </div>
            ) : (
                <p>Carregando dados...</p>
            )}

            <WaterConsumptionCard 
                percentage={57} 
                consumption={120} 
            />
        </div>
    );
    // ---------------------------------------------------------------------------------
};

export default DashBoard;
