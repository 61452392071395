import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import "./RegisterClientPage.css";

const RegisterClientPage = () => {
    const { managerId } = useParams(); // Pega o id da URL
    const [formData, setFormData] = useState({
        manager: '', // Preenche o manager com o id da URL
        range: '',
        name: '',
        num_units: '',
        address: null, // O ID do endereço será anexado aqui após criação
        is_active: true,
        is_individualized: false,
        external_id: '',
        whatsapp_enabled: false,
    });

    const [addressData, setAddressData] = useState({
        state: 'DF',
        city: '',
        zip_code: '',
    });

    const [contactData, setContactData] = useState({
        email: '',
        phone: '',
        is_primary: true,
    });

    const [ranges, setRanges] = useState([]); // Estado para armazenar os ranges da API
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate()

    // Busca os ranges da API ao carregar o componente
    useEffect(() => {
        const fetchRanges = async () => {
            try {
                const response = await axios.get('http://api.akvofluo.com/api/ranges/');
                setRanges(response.data); // Define os ranges no estado
            } catch (error) {
                console.error("Erro ao buscar faixas de tarifação:", error);
                setErrorMessage("Erro ao carregar as faixas de tarifação.");
            }
        };
        
        fetchRanges();
    }, []); // Executa apenas uma vez quando o componente é montado

    // Atualiza os dados do formulário
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (Object.keys(formData).includes(name)) {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value,
            });
        } else if (Object.keys(addressData).includes(name)) {
            setAddressData({
                ...addressData,
                [name]: value,
            });
        } else {
            setContactData({
                ...contactData,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };

    // Envia o formulário
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Manager ID (id from URL):", managerId);
        try {
            // Cria o endereço primeiro
            const addressResponse = await axios.post('http://api.akvofluo.com/api/addresses/', addressData);
            const addressId = addressResponse.data.id;

            // Adiciona o ID do endereço ao payload do cliente
            const clientPayload = { ...formData, address: addressId, manager: managerId };

            // Cria o cliente
            const clientResponse = await axios.post('http://api.akvofluo.com/api/clients/', clientPayload);
            const clientId = clientResponse.data.id;

            // Cria o contato associado ao cliente
            await axios.post('http://api.akvofluo.com/api/client-contacts/', {
                ...contactData,
                client: clientId,
            });

            // Reseta os campos do formulário
            setFormData({
                manager: '',
                range: '',
                name: '',
                num_units: '',
                address: null,
                is_active: true,
                is_individualized: false,
                external_id: '',
                whatsapp_enabled: false,
            });

            setAddressData({
                state: 'DF',
                city: '',
                zip_code: '',
            });

            setContactData({
                email: '',
                phone: '',
                is_primary: true,
            });

            setSuccessMessage('Cliente, endereço e contato cadastrados com sucesso!');
            setErrorMessage('')

            navigate(`/dashboard/`);

        } catch (error) {
            if (error.response) {
                setErrorMessage(`Erro: ${JSON.stringify(error.response.data)}`);
            } else {
                setErrorMessage('Erro ao cadastrar cliente, endereço ou contato.');
            }
        }
    };

    return (
        <div className="page-container">
            <div className="form-box">
                <h2>Cadastrar Cliente</h2>
                <form onSubmit={handleSubmit}>
                    {/* Inputs do Cliente */}
                    <div className="form-group">
                        <label htmlFor="range">Faixa de Tarifação</label>
                        <select
                            id="range"
                            name="range"
                            value={formData.range}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Selecione uma faixa</option>
                            {ranges.map((range) => (
                                <option key={range.id} value={range.id}>
                                    {range.tax_type} {range.company}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nome do condomínio"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="num_units">Número de Unidades</label>
                        <input
                            type="number"
                            id="num_units"
                            name="num_units"
                            placeholder="Número de unidades residenciais"
                            value={formData.num_units}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Inputs do Endereço */}
                    <h3>Endereço</h3>
                    <div className="form-group">
                        <label htmlFor="state">Estado</label>
                        <select
                            id="state"
                            name="state"
                            value={addressData.state}
                            onChange={handleChange}
                            required
                        >
                            <option value="DF">DF</option>
                            <option value="SP">SP</option>
                            <option value="RJ">RJ</option>
                            {/* Adicione outros estados conforme necessário */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">Cidade</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            placeholder="Cidade"
                            value={addressData.city}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zip_code">CEP</label>
                        <input
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            placeholder="CEP"
                            value={addressData.zip_code}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {/* Inputs do Contato */}
                    <h3>Contato Principal</h3>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={contactData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Telefone</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Telefone"
                            value={contactData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="is_primary">Contato Principal</label>
                        <input
                            type="checkbox"
                            id="is_primary"
                            name="is_primary"
                            checked={contactData.is_primary}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit">Cadastrar</button>
                </form>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
            </div>
        </div>
    );
};

export default RegisterClientPage;
