import React from 'react';
import './Tooltip.css'; // Estilos do tooltip

const LabelWithTooltipAndAsterisk = ({ label, tooltipImage, required, children }) => {
  return (
    <div className="label-with-tooltip">
      <label>
        {label} {required && <span className="asterisk">*</span>}
        <span className="tooltip-icon">?</span>
        <div className="tooltip-content">
          <img src={tooltipImage} alt="Tooltip" />
        </div>
      </label>
      {children}
    </div>
  );
};

export default LabelWithTooltipAndAsterisk;