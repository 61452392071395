import React from 'react';
import NavBarHome from '../components/NavBarHome/NavBarHome';
import Footer from '../components/Footer/Footer';
import { Outlet } from 'react-router-dom';

const PublicLayout = () => {
    return (
        <>
            <NavBarHome />
            <Outlet />
            <Footer />
        </>
    );
};

export default PublicLayout;
