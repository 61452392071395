// src/components/Footer/Footer.js

import React from 'react';
import './Footer.css';
import logoAkvofluo from '../../assets/images/akvofluo_logo.jpeg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <img src={logoAkvofluo} alt="Akvofluo Logo" className="footer-logo" />
                    <p>Preservando a água, preservando o futuro!</p>
                </div>
                <div className="footer-section">
                    <h4>Quem Somos</h4>
                    <p>Fale com os Fundadores</p>
                </div>
                <div className="footer-section">
                    <h4>Invista no futuro</h4>
                </div>
                <div className="footer-section">
                    <p>suporte@akvofluo.com.br</p>
                    <p>contato@akvofluo.com.br</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; Akvofluo. Todos os direitos reservados.</p>
            </div>
        </footer>
    );
};

export default Footer;
