import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/auth';
import { AuthContext } from '../../AuthContext';
import logoAkvofluo from '../../assets/images/akvofluo_logo.jpeg'
import './LoginPage.css';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await login(username, password);
        if (response.success) {
            setIsAuthenticated(true); // Atualiza o estado global de autenticação
            navigate('/dashboard');  // Redireciona para o dashboard
        } else {
            setErrorMessage(response.message || 'Erro ao fazer login');
        }
    };

    return (
        <div className="login-page">
            <div className="login-form-container">
            <img src={logoAkvofluo} alt="Akvofluo Logo" className="logo" />
                <form onSubmit={handleSubmit}>
                    <input
                        type="username"
                        placeholder="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Entrar</button>
                    {errorMessage && <p className="error">{errorMessage}</p>}
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
