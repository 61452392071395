import React, { useState } from 'react';
import './FileUpload.css';

const FileUpload = ({ selectedFiles, setSelectedFiles }) => {
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false); // Retorna ao estado normal após soltar
    const newFiles = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true); // Mantém o estado de arraste enquanto sobre a área de upload
  };

  return (
    <div
      className={`file-upload ${dragging ? 'dragging' : ''}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={() => setDragging(false)} // Reseta o estado se sair da área
    >
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        accept="image/*"
      />
      <p>Arraste e solte as imagens ou clique para selecionar</p>
      <div className="preview-container">
        {selectedFiles && selectedFiles.map((file, index) => (
          <img
            key={index}
            src={URL.createObjectURL(file)}
            alt={`preview-${index}`}
            className="preview-image"
          />
        ))}
      </div>
    </div>
  );
};

export default FileUpload;