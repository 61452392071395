// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getAccessToken, logout } from './api/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!getAccessToken());

    useEffect(() => {
        setIsAuthenticated(!!getAccessToken());
    }, []);

    const handleLogout = () => {
        logout();
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};
