import React, { useState, useEffect } from 'react';
import api from '../../api/client'; // Importa a configuração do cliente API
import './ConsumptionHistoryPage.css';

const ConsumptionHistoryPage = () => {
    const [data, setData] = useState([]); // Inicializamos com um array vazio
    const [editingRow, setEditingRow] = useState(null); // Linha que está sendo editada
    const [editVolume, setEditVolume] = useState(''); // Valor do volume editado
    const [error, setError] = useState(null); // Estado para mensagens de erro

    // Função para buscar os dados da API
    const fetchData = async () => {
        try {
            const response = await api.get('/consumption-history/'); // Chame o endpoint correto da sua API
            setData(response.data); // Atualiza os dados com a resposta da API
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setError('Não foi possível carregar os dados do histórico de consumo');
        }
    };

    // Carrega os dados ao montar o componente
    useEffect(() => {
        fetchData();
    }, []);

    // Função de edição
    const handleEdit = (index) => {
        setEditingRow(index);
        setEditVolume(data[index].volume);
    };

    // Função para salvar as edições
    const handleSave = async (id) => {
        try {
            const updatedRow = { ...data.find(row => row.id === id), volume: parseFloat(editVolume) };
            await api.put(`/consumption-history/${id}/`, updatedRow); // Envia o PUT para a API
            setData(data.map(row => row.id === id ? updatedRow : row));
            setEditingRow(null);
        } catch (error) {
            console.error('Erro ao salvar edição:', error);
            setError('Não foi possível salvar as alterações');
        }
    };

    // Função para cancelar a edição
    const handleCancel = () => {
        setEditingRow(null);
    };

    // Função de exclusão
    const handleDelete = async (id) => {
        try {
            await api.delete(`/consumption-history/${id}/`); // Envia o DELETE para a API
            setData(data.filter(row => row.id !== id));
        } catch (error) {
            console.error('Erro ao excluir registro:', error);
            setError('Não foi possível excluir o registro');
        }
    };

    return (
        <div className="consumption-history-page">
            <h2>Histórico de Consumo</h2>
            {error && <p className="error-message">{error}</p>}
            <table className="consumption-history-table">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Volume (m³)</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={row.id}>
                            <td>{row.date}</td>
                            <td>
                                {editingRow === index ? (
                                    <input
                                        type="number"
                                        value={editVolume}
                                        onChange={(e) => setEditVolume(e.target.value)}
                                        className="edit-input"
                                    />
                                ) : (
                                    row.volume.toFixed(1)
                                )}
                            </td>
                            <td>
                                {editingRow === index ? (
                                    <>
                                        <button
                                            className="save-button"
                                            onClick={() => handleSave(row.id)}
                                        >
                                            Salvar
                                        </button>
                                        <button
                                            className="cancel-button"
                                            onClick={handleCancel}
                                        >
                                            Cancelar
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="edit-button"
                                            onClick={() => handleEdit(index)}
                                        >
                                            Editar
                                        </button>
                                        <button
                                            className="delete-button"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            Apagar
                                        </button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ConsumptionHistoryPage;
