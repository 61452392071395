import React from 'react';
import './InstructionsPage.css';

const InstructionsPage = () => {
    return (
        <div className="instructions-page">
            <h2>Instruções</h2>
            <p>O sistema é desenvolvido com usabilidade amigável, contudo seguem as instruções, caso exista alguma dúvida.</p>
            <p>Estamos sempre em evolução para promover o consumo consciente, caso possua sugestões, não hesite em entrar em contato com nossa equipe</p>
            <p>Contato: suporte@akvofluo.com.br</p>

            <div className="instruction-list">
                <ul>
                    <li><a href="#routine-readings">Como cadastrar leituras de rotina? [Diariamente]</a></li>
                    <li><a href="#new-bills">Como cadastrar contas faturadas novas? [Mensalmente]</a></li>
                    <li><a href="#new-user">Como cadastrar novo do usuário? [Somente uma vez]</a></li>
                    <li><a href="#edit-client-profile">Como alterar dados do perfil do cliente? [Somente uma vez]</a></li>
                    <li><a href="#sensor-data">Como cadastrar dados do sensor monitorado? [Somente uma vez]</a></li>
                    <li><a href="#old-bills">Como cadastrar contas antigas? [Somente uma vez]</a></li>
                </ul>
            </div>

            <section id="routine-readings" className="instruction-section">
                <h3>Como Cadastrar Leituras de Rotina?</h3>
                <p>O consumo deve ser monitorado frequentemente para que o sistema possa identificar padrões de consumo e alertar os usuários sobre a possibilidade de vazamentos, mudança de faixa de tarifação e anomalias de consumo.</p>
                <p><strong>Frequência:</strong> Ideal que seja feita diariamente ou com a maior frequência possível.</p>
                <p><strong>Tempo Estimado de Execução:</strong> 20 a 45 segundos</p>
                <p><strong>Quando:</strong> Frequentemente</p>
                {/* Adicione a imagem de exemplo aqui */}
            </section>

            <section id="new-bills" className="instruction-section">
                <h3>Como cadastrar contas faturadas novas?</h3>
                <p>Os dados cadastrados mensalmente das faturas enviadas pela Concessionária de água permitem que a Akvofluo alinhe os dados de consumo com o intervalo de faturamento.</p>
                <p><strong>Frequência:</strong> Mensalmente (sempre que a conta de água for entregue)</p>
                <p><strong>Tempo Estimado de Execução:</strong> 1 minuto</p>
                {/* Adicione a imagem de exemplo aqui */}
            </section>

            <section id="new-user" className="instruction-section">
                <h3>Como Cadastrar novo do Usuário?</h3>
                <p>As informações de usuário são utilizadas para acesso ao sistema da Akvofluo e recuperação de senha.</p>
                <p><strong>Frequência:</strong> Somente uma vez</p>
                <p><strong>Tempo Estimado de Execução:</strong> 20 segundos</p>
                {/* Adicione a imagem de exemplo aqui */}
            </section>

            <section id="edit-client-profile" className="instruction-section">
                <h3>Como cadastrar/alterar dados do Perfil do Cliente?</h3>
                <p>As informações do perfil do cliente devem ser inseridas ao se cadastrar na Akvofluo e tem como objetivo fornecer dados referentes ao tipo de consumidor.</p>
                <p><strong>Frequência:</strong> Somente uma vez</p>
                <p><strong>Tempo Estimado de Execução:</strong> 1 minuto</p>
                {/* Adicione a imagem de exemplo aqui */}
            </section>

            <section id="sensor-data" className="instruction-section">
                <h3>Como cadastrar dados do Sensor Monitorado?</h3>
                <p>Os dados do sensor monitorado devem ser preenchidos logo após o cadastro do usuário e cliente na Akvofluo e tem como objetivo caracterizar os dados do tipo de equipamento de medição.</p>
                <p><strong>Frequência:</strong> Somente uma vez</p>
                <p><strong>Tempo Estimado de Execução:</strong> 3 minutos</p>
                {/* Adicione a imagem de exemplo aqui */}
            </section>

            <section id="old-bills" className="instruction-section">
                <h3>Como cadastrar contas antigas?</h3>
                <p>Os dados referentes à contas antigas devem ser inseridos logo após o cadastro do cliente na Akvofluo e tem como objetivo fornecer ao menos um histórico simples da média de consumo dos clientes para avaliar a possível evolução na conscientização do consumo de água.</p>
                <p><strong>Frequência:</strong> Somente uma vez</p>
                <p><strong>Tempo Estimado de Execução:</strong> 1 minuto</p>
                {/* Adicione a imagem de exemplo aqui */}
            </section>
        </div>
    );
};

export default InstructionsPage;
