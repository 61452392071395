// src/api/auth.js
import axios from 'axios';

const API_URL = 'http://api.akvofluo.com/api/token/'; // URL da API para login

export const login = async (email, password) => {
    try {
        const response = await axios.post(API_URL, { username: email, password });
        const { access, refresh } = response.data;

        // Salvar os tokens
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);

        return { success: true };
    } catch (error) {
        console.error('Erro ao fazer login:', error);
        return { success: false, message: error.response?.data || 'Erro desconhecido' };
    }
};

export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
};

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};
