import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'http://api.akvofluo.com', // Troque para HTTPS se o servidor suportar
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*', // Permite qualquer origem (ajuste conforme necessário)
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  },
});

export default apiClient;