import React from 'react';
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/akvofluo_logo.jpeg'
import './NavBarHome.css';

const NavBarHome = () => {
    const navigate = useNavigate();

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logo} alt= "akvo-logo-navbar"/>
                
            </div>
            <div className="navbar-button">
                <button className="nav-button login" onClick={() => navigate('/login')}>Login</button>
                <button className="nav-button register" onClick={() => navigate('/register')}>Cadastro</button>
            </div>
        </nav>
    );
};

export default NavBarHome;
